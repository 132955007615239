import Countdown from "react-countdown";
import AOS from "aos";
import "aos/dist/aos.css";

import logo from "./assets/img/Scam-Brand-Main.png";
import circle from "./assets/img/Circle.png";
import roadmap from "./assets/img/road-map-bg.png";
import "./App.css";
import { useEffect } from "react";

function pad2(num) {
  return ("0" + num).substr(-2);
}
function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
    });
    AOS.refresh();
  }, []);

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flex flex-col gap-3 lg:gap-12 lg:flex-row">
        <p className="text-center w-[100px]">
          <span className="text-[80px] font-black block mb-[-15px]">
            {pad2(days)}
          </span>
          <span>Days</span>
        </p>
        <p className="text-center w-[100px]">
          <span className="text-[80px] font-black block mb-[-15px]">
            {pad2(hours)}
          </span>
          <span>Hours</span>
        </p>
        <p className="text-center w-[100px]">
          <span className="text-[80px] font-black block mb-[-15px]">
            {pad2(minutes)}
          </span>
          <span>Minutes</span>
        </p>
        <p className="text-center w-[100px]">
          <span className="text-[80px] font-black block mb-[-15px]">
            {pad2(seconds)}
          </span>
          <span>Seconds</span>
        </p>
      </div>
    );
  };

  return (
    <div className="App">
      <div className="bg-[#000]">
        <div className="bg-[url('./assets/img/hero-herd2-1.png')] bg-cover bg-no-repeat bg-center py-16">
          <header className="max-w-5xl mx-auto flex flex-col justify-center items-center pt-16 text-center relative">
            <div className="flex justify-center items-center flex-col relative z-10 w-full">
              <div className="max-w-[130px]">
                <img src={logo} alt="" />
              </div>
              <h1 className="text-primary my-[-10px]">RAPTORDOGE</h1>
              <h3 className="text-2xl font-mont font-black text-white-1">
                He hodled for your profits
              </h3>
              <h4 className="text-xl font-mont my-6">
                RaptorDoge, a crypto meme originating from the wild wilderness
                of the internet in 2023, has achieved the coveted status of
                "Most Wow" in the Hall of Fame of meme-infused cryptocurrencies.
              </h4>
              <span className="block text-primary-2">LAUNCH</span>
              {/* <Countdown
                date={new Date(2023, 5, 22, 2)}
                className="text-white-2"
                renderer={renderer}></Countdown> */}
              <div className="pt-12 py-16 flex items-center gap-10">
                <a
                  href="https://t.me/RaptorDogeETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[50px] h-[50px] bg-white rounded-full overflow-hidden inline-flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    className="h-[25px]">
                    <path
                      d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                      fill="#1e8556"
                    />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/RaptorDogeETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[50px] h-[50px] overflow-hidden inline-flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    className="w-full h-full">
                    <path
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      fill="#1e8556"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </header>
        </div>

        <main>
          <div
            className="bg-[#0A0107] bg-[url('./assets/img/hero-hacket.png')] bg-cover bg-no-repeat pt-[50px] overflow-hidden lg:pt-[100px]"
            data-aos="fade-up">
            <div className="py-16 container px-3 mx-auto flex flex-col justify-center items-center  text-center relative lg:px-16">
              <div className="flex justify-center items-center flex-col relative z-10">
                <h1 className="text-primary-3">ToTheMoon-velations</h1>
                <p className="text-secondary my-6 leading-[50px] max-w-7xl pb-12">
                  RaptorDoge, the stuff of legends from the chaotic realm of
                  Crypto Twitter, features a comically photoshopped image of
                  Doge's face onto a ferocious raptor's body. It's as
                  terrifyingly adorable as it sounds.
                  <br />
                  <br />
                  The whispers around the crypto watering holes speculate that
                  during the early days of the blockchain epoch, Satoshi went
                  through a "Wild Dino Phase". This era resulted in the crypto
                  jungle teeming with oversized, unruly meme coins. These
                  "GigaChads of Crypto," known as memecoins, wreaked havoc until
                  Satoshi had a facepalm moment and invented RaptorDoge.
                  <br />
                  <br />
                  RaptorDoge, with his diamond-clawed paws, aimed to convert
                  these unruly memecoins into something more than just a quick
                  pump-and-dump. He faced opposition from the "No Fun Fudsters"
                  who didn't believe in the power of memes.
                  <br />
                  <br />
                  Miracles, such as unexpected bull runs and surviving crypto
                  winters, were attributed to RaptorDoge. Eventually, Satoshi
                  stepped in, wiped out the scam coins, and began the "New Meme
                  Order". The teachings of RaptorDoge were compiled in the
                  sacred "RaptorDoge Whitepaper," which includes popular sayings
                  like "Much Wow, So Hodl" and prophecies of 1 RaptorDoge= 1
                  Lambo.
                  <br />
                  <br />
                  `` The final section of the whitepaper paints a vision of the
                  "VelociRupture" and "CryptoMageddon", where only the true
                  believers in RaptorDoge will hodl and find their way to the
                  moon.
                </p>
                <a
                  href="https://t.me/RaptorDogeETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[350px] h-[60px] flex items-center justify-center gap-5 bg-white rounded-2xl btn-primary">
                  <div className="flex w-[342px] h-[52px] items-center justify-center gap-5 rounded-xl bg-[#1e8556]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      className="h-[22px]">
                      <path
                        d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                        fill="#000000F5"
                      />
                    </svg>
                    <span className="text-[#000000F5] font-black text-xl">
                      JOIN TELEGRAM
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* <div className="bg-[#000] py-5 lg:py-16" data-aos="fade-left">
            <div className="container px-13 mx-auto flex flex-col justify-center items-center py-14 text-center relative lg:px-16">
              <div className="flex justify-center items-center flex-col relative z-10">
                <div className="max-w-[160px]">
                  <img src={logo} alt="" />
                </div>
                <h1 className="text-primary my-[-20px]">JOIN DOGELORE</h1>
                <h3 className="text-3xl font-mont font-black text-white-1 lg:text-5xl">
                  RESEARCH TEAM
                </h3>
                <p className="text-secondary font-mont my-7 leading-10 max-w-7xl">
                  Our research and investigations team consists of 10+ members
                  who are fully dedicated to prevent you from getting scammed.
                  Our culture movement aims to clean up the space.
                </p>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-10 w-[350px] h-[60px] flex items-center justify-center gap-5 bg-white rounded-2xl btn-primary">
                  <div className="flex w-[342px] h-[52px] items-center justify-center gap-5 rounded-xl bg-[#1e8556]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                      className="h-[25px]">
                      <path
                        d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"
                        fill="#000000F5"
                      />
                    </svg>
                    <span className="text-[#000000F5] font-black text-xl">
                      WHITEPPAPER
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div> */}

          <div className="bg-[#000] py-5 lg:py-16" data-aos="fade-right">
            <div className="container px-3 mx-auto flex flex-col justify-center items-center py-14 text-center relative lg:px-16">
              <div className="w-full flex justify-center items-center flex-col relative z-10">
                <div className="max-w-[180px]">
                  <img src={logo} alt="" />
                </div>
                <h2 className="text-tokenomics my-[20px]">TOKENOMICS</h2>
                <h3 className="text-3xl font-mont font-black text-white-1">
                  TOKEN DISTRIBUTION
                </h3>

                <div className="my-12">
                  <span className="bg-[#1e8556] h-[4px] w-[50px] inline-block"></span>
                </div>

                <div className="w-full">
                  <div className="relative">
                    <div className="flex justify-center gap-5 lg:gap-10">
                      <div className="w-[200px] relative">
                        <img className="w-full" src={circle} alt="circle" />
                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%]	 translate-x-[-50%]	flex flex-col">
                          <span className="text-tokenomics-2 mb-[-20px]">
                            0%
                          </span>
                          <span className="text-xl">Buy Tax</span>
                        </div>
                      </div>
                      <div className="w-[200px] relative">
                        <img className="w-full" src={circle} alt="circle" />
                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%]	 translate-x-[-50%]	flex flex-col">
                          <span className="text-tokenomics-2 mb-[-20px]">
                            0%
                          </span>
                          <span className="text-xl">Sell Tax</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-16 w-[250px] h-[55px] flex items-center justify-center gap-5 bg-white rounded-2xl btn-primary">
                  <div className="flex w-[242px] h-[47px] items-center justify-center gap-5 rounded-xl bg-[#1e8556]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                      className="h-[25px]">
                      <path
                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                        fill="#000000F5"
                      />
                    </svg>
                    <span className="text-[#000000F5] font-black text-xl">
                      BUY NOW
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="bg-[#D8DEBD] p-3 mt-16" data-aos="fade-up">
            <div className="bg-[#1e8556] p-10 text-center">
              <h2 className="font-black text-4xl text-black lg:text-7xl">
                JOIN DOGELORE
              </h2>
              <div className="pt-12 flex justify-center items-center gap-10">
                <a
                  href="https://t.me/RaptorDogeETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[40px] h-[40px] bg-black rounded-full overflow-hidden inline-flex justify-center items-center lg:w-[50px] lg:h-[50px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    className="h-[25px]">
                    <path
                      d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                      fill="#1e8556"
                    />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/RaptorDogeETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-[40px] h-[40px] overflow-hidden inline-flex justify-center items-center lg:w-[50px] lg:h-[50px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    className="w-full h-full">
                    <path
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                      fill="#000"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          {/* <div className="bg-[#211b13] text-center py-[150px] lg:py-[400px] bg-[url('./assets/img/hero-herd2-1.png')] bg-center bg-no-repeat bg-cover">
            <h2 className="font-mont text-3xl font-black lg:text-6xl">
              THE BIGGEST SCAM IN CRYPTO
            </h2>
          </div> */}

          <div className="bg-[#D8DEBD] p-3 mt-12" data-aos="fade-up">
            <div className="bg-[#1e8556] p-10 text-center">
              <h2 className="font-black text-3xl text-black lg:text-7xl">
                When is a doge not a dog? When it's a raptor
              </h2>
            </div>
          </div>

          {/* <div
            className="bg-[#000] py-[40px] text-center lg:py-[100px]"
            data-aos="fade-up">
            <h2 className="text-tokenomics my-[20px]">THE TEAM</h2>
            <div className="my-8 flex flex-col justify-center items-center lg:my-12">
              <div className="max-w-[200px]">
                <img src={logo} alt="" />
              </div>
              <h2 className="text-tokenomics mt-[10px] mb-[-10px]">ADAM</h2>
              <h3 class="text-xl font-mont font-black text-[#DBD6D6] lg:text-3xl">
                CHIEF SCAMMER
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="h-[20px] mt-2">
                <path
                  d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                  fill="#fff"
                />
              </svg>
            </div>
            <div className="mt-8 flex flex-col gap-5 justify-center lg:flex-row lg:gap-[150px] lg:mt-12">
              <div className="my-8 flex flex-col justify-center items-center lg:my-12">
                <div className="max-w-[180px] lg:max-w-[230px]">
                  <img src={logo} alt="" />
                </div>
                <h2 className="text-tokenomics mt-[10px] mb-[-10px]">PAPI</h2>
                <h3 class="text-xl font-mont font-black text-[#DBD6D6] lg:text-3xl">
                  MARKETING SCAM
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-[20px] mt-2">
                  <path
                    d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div className="my-12 flex flex-col justify-center items-center">
                <div className="max-w-[180px] lg:max-w-[230px]">
                  <img src={logo} alt="" />
                </div>
                <h2 className="text-tokenomics mt-[10px] mb-[-10px]">CHENZO</h2>
                <h3 class="text-xl font-mont font-black text-[#DBD6D6] lg:text-3xl">
                  SCAM ARCHITECT
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-[20px] mt-2">
                  <path
                    d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-12 flex flex-col gap-5 justify-center lg:flex-row lg:gap-[150px]">
              <div className="my-12 flex flex-col justify-center items-center">
                <div className="max-w-[180px] lg:max-w-[230px]">
                  <img src={logo} alt="" />
                </div>
                <h2 className="text-tokenomics mt-[10px] mb-[-10px]">
                  SERGEANT
                </h2>
                <h3 class="text-xl font-mont font-black text-[#DBD6D6] lg:text-3xl">
                  COMMUNITY SCAMMER
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-[20px] mt-2">
                  <path
                    d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div className="my-12 flex flex-col justify-center items-center">
                <div className="max-w-[180px] lg:max-w-[230px]">
                  <img src={logo} alt="" />
                </div>
                <h2 className="text-tokenomics mt-[10px] mb-[-10px]">
                  CRYPTO KID
                </h2>
                <h3 class="text-xl font-mont font-black text-[#DBD6D6] lg:text-3xl">
                  THE GATEKEEPER
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-[20px] mt-2">
                  <path
                    d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </div>
          </div> */}

          <div
            className="py-[50px] text-center lg:py-[100px]"
            data-aos="fade-up">
            <h2 className="text-tokenomics my-[80px]">ROADMAP</h2>
            <div className="flex flex-col items-center gap-[80px] justify-center lg:gap-[150px] lg:flex-row">
              <div className="h-[600px] relative">
                <img className="h-full" src={roadmap} alt="" />
                <div className="w-full absolute top-14 left-[50%] translate-x-[-50%]">
                  <h2 className="text-tokenomics-4">PHASE 1</h2>
                  <ul className="my-5 text-center flex flex-col gap-3 pl-12">
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        className="h-[25px]">
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Socials
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        className="h-[25px]">
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Website Live
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        className="h-[25px]">
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        SC Deploy
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        className="h-[25px]">
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Uniswap Listing
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        className="h-[25px]">
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Community Engagement
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                        className="h-[25px]">
                        <path
                          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        First Marketing Campaign
                      </span>
                    </li>
                  </ul>
                  <h4 className="text-tokenomics-5">IN PROGRESS</h4>
                </div>
              </div>
              <div className="h-[600px] relative">
                <img className="h-full" src={roadmap} alt="" />
                <div className="w-full absolute top-14 left-[50%] translate-x-[-50%]">
                  <h2 className="text-tokenomics-4">PHASE 2</h2>
                  <ul className="my-5 text-center flex flex-col gap-3 pl-12">
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        className="h-[25px]">
                        <path
                          d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        CMC Listing
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        className="h-[25px]">
                        <path
                          d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        CG Listing
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        className="h-[25px]">
                        <path
                          d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Partnerships
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        className="h-[25px]">
                        <path
                          d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Buyback & Burn
                      </span>
                    </li>
                    <li className="flex items-center gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                        className="h-[25px]">
                        <path
                          d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"
                          fill="#1e8556"
                        />
                      </svg>
                      <span className="font-mont text-xl font-extrabold text-[#d6d7da]">
                        Cex listing
                      </span>
                    </li>
                  </ul>
                  <h4 className="text-tokenomics-5">IN PROGRESS</h4>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer
          className="bg-[#1e8556] relative overflow-hidden py-3 lg:py-16"
          data-aos="fade-up">
          <div className="relative z-10 pt-12 py-16 flex items-center justify-center gap-16">
            <a
              href="https://t.me/RaptorDogeETH"
              target="_blank"
              rel="noopener noreferrer"
              className="w-[50px] h-[50px] bg-white rounded-full overflow-hidden inline-flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="h-[25px]">
                <path
                  d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                  fill="#1e8556"
                />
              </svg>
            </a>
            <a
              href="https://twitter.com/RaptorDogeETH"
              target="_blank"
              rel="noopener noreferrer"
              className="w-[50px] h-[50px] overflow-hidden inline-flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="w-full h-full">
                <path
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                  fill="#fff"
                />
              </svg>
            </a>
          </div>
        </footer>

        <div className="bg-[#000] text-center py-5">
          <span className="text-md">Made With DOGELORE © 2023</span>
        </div>
      </div>
    </div>
  );
}

export default App;
